import React from "react";

import logo from "../../../assets/logo.svg";
//import arrow from "../../../assets/arrow.svg";
import img from "../../../assets/checklist_end.png";
import tgIcon from "../../../assets/tg_icon.svg";

import ChecklistWrapper from "../ChecklistWrapper";

export default function MistakesEnd() {
  return (
    <ChecklistWrapper>
      <div className="checklist__main checklist__finish">
        <div className="desktop checklist__logo">
          <img src={logo} alt="" />
        </div>

        <div className="row space-between">
          <div className="checklist__leftside">
            <div className="mobile checklist__logo">
              <img src={logo} alt="" />
            </div>
            <div className="checklist__title purple">
              Спасибо,<span className="orange"> материал
              <br />
              ждет вас </span>в канале
            </div>
            <div className="checklist__subtitle">
              
              <div>
                Подпишитесь и получайте каждую
                <br />
                неделю новую подборку
              </div>
            </div>

            <a
              href="https://t.me/DoctorlawBot"
              className="desktop button button_rectangle button_tg "
              style={{
                width: "290px",
              }}
              target="_blank"
            >
              <img
                src={tgIcon}
                alt=""
                width={19}
                height={17}
                style={{ marginRight: "16px" }}
              />
              Telegram
            </a>
          </div>
          <div className="checklist__rightside">
            <span style={{textAlign: 'right'}} className="checklist__rightside__bottom-title">
              ТОП-5 юридических<br/>ошибок<br/>предпринимателей
            </span>
            <div className="checklist__rightside__img-container">
              <img className="img" src={img} alt="" />
            </div>

            <a
              href="https://t.me/DoctorlawBot"
              className="mobile button button_rectangle button_tg"
              style={{
                width: "290px",
              }}
            >
              <img
                src={tgIcon}
                alt=""
                width={19}
                height={17}
                style={{ marginRight: "16px" }}
              />
              Telegram
            </a>
          </div>
        </div>
      </div>
    </ChecklistWrapper>
  );
}
