
import { Route, Routes } from 'react-router-dom';

import Mistakes from './pages/checklist/mistakes/Mistakes';
import MistakesForm from './pages/checklist/mistakes/MistakesForm';
import MistakesEnd from './pages/checklist/mistakes/MistakesEnd';

import './index.css'



function App() {
  return (
    <Routes>
        <Route path='/mistakes' element={<Mistakes />} />
        <Route path='/mistakes/form' element={<MistakesForm />} />
        <Route path='/mistakes/end' element={<MistakesEnd/>} />
    </Routes>
  );
}

export default App;
