import React from "react";
import { Link } from "react-router-dom";

import ChecklistWrapper from "../ChecklistWrapper";

import logo from "../../../assets/logo.svg";
import arrow from "../../../assets/arrow.svg";
import img from "../../../assets/mistakes_main.png";

const Mistakes = () => {
  return (
    <ChecklistWrapper>
      <div className="checklist__main">
        <div className="desktop checklist__logo">
          <img src={logo} alt="" />
        </div>

        <div className="row space-between">
          <div className="checklist__leftside">
            <div className="mobile checklist__logo">
              <img src={logo} alt="" />
            </div>
            <div className="checklist__title">
              <span className="orange">
                Какие юридические
                <br />
                ошибки
              </span>{" "}
              могут уничтожить
              <br />
              <span>ваш бизнес?</span>
            </div>
            <div className="checklist__subtitle">
              <div>
                Скачайте чек-лист, чтобы узнать, как
                <br />
                избежать ошибок и сохранить ваше дело
              </div>

              <span className="mobile checklist__arrows">
                <img src={arrow} alt="" width='30px' />
              </span>
            </div>
            <div className="desktop" style={{display:'flex', alignItems: "center" }}>
              <Link
                to="/mistakes/form"
                className="button button_rectangle"
                style={{ width: "290px" }}
              >
                Скачать чек-лист
              </Link>
              <span className="checklist__arrows">
                <img src={arrow} alt="" />
              </span>
            </div>
          </div>
          <div className="checklist__rightside">
            {/* 
            <div className="checklist__rightside__decor">
              <div className="checklist__rightside__rect">
                <div className="checklist__rightside__rect_inner" />
              </div>
            </div>
            */}

            <div className="checklist__rightside__img-container">
              <img className="img" src={img} alt="" />
            </div>

            <Link
              to="/mistakes/form"
              className="mobile button button_rectangle"
              style={{ width: "290px" }}
            >
              Скачать чек-лист
            </Link>
          </div>
        </div>
      </div>
    </ChecklistWrapper>
  );
};

export default Mistakes;
