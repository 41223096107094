import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ChecklistWrapper from "../ChecklistWrapper";

import axios from "axios";

//import privacy_policy from "../../assets/privacy_policy.pdf";

export default function MistakesForm() {
  const navigate = useNavigate();



  const [loading, setLoading] = useState(false)

  const [name, setName] = useState("");
  const [nameDirty, setNameDirty] = useState(false);
  const [nameError, setNameError] = useState("Поле не может быть пустым");

  const [phone, setPhone] = useState("");
  const [phoneDirty, setPhoneDirty] = useState(false);
  const [phoneError, setPhoneError] = useState("Поле не может быть пустым");

  const [email, setEmail] = useState("");
  const [emailDirty, setEmailDirty] = useState(false);
  const [emailError, setEmailError] = useState("Поле не может быть пустым");

  const handlePhone = (e) => {
    setPhone(e.target.value);
    let re =
      /^(\+7|8)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/;
    if (!re.test(String(e.target.value))) {
      setPhoneError("Неверный формат телефона");
    } else {
      setPhoneError("");
    }
  };

  const handleName = (e) => {
    setName(e.target.value);
    if (!e.target.value) {
      setNameError("Поле не может быть пустым");
    } else {
      setNameError("");
    }
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
    let re = /^\S+@\S+\.\S+$/;
    if (!re.test(String(e.target.value))) {
      setEmailError("Неверный формат email");
    } else {
      setEmailError("");
    }
  };

  const handleSubmit = async (e) => {
    let formattedPhone = phone
      .replace("(", "")
      .replace(")", "")
      .split("-")
      .join("")
      .split(" ")
      .join("");
    formattedPhone =
      formattedPhone.substring(0, 2) == "+7"
        ? formattedPhone
        : formattedPhone.replace("8", "+7");

    const API_URL =
      "https://script.google.com/macros/s/AKfycbz9d0uzUZCybFpgBOSN-7qxkH1YVjHvIj5suVpw0CFovOt1treorg41ps2w7rc_idJY/exec";

    const encoded = new URLSearchParams({
      name: name,
      phone: "'" + formattedPhone,
      email: email,
    });

    setLoading(true)

    await axios
      .get(API_URL, {
        params: encoded,
      })
      .then((res) => {
        navigate("/mistakes/end");
      })
      .catch((res) => console.log(res)).finally(() => navigate("/mistakes/end"));
  };

  return (
    <ChecklistWrapper>
      <div className="checklist__form">
        <span className="decor__free">БЕСПЛАТНО</span>
        <span className="form__subtitle">чек-лист</span>
        <h4 className="form__title">
          «ТОП-5 ЮРИДИЧЕСКИХ ОШИБОК ПРЕДПРИНИМАТЕЛЕЙ»
        </h4>

        {nameDirty && nameError && (
          <span style={{ color: "#FF3A3A", fontSize: "12px", margin: "8px 0" }}>
            {nameError}
          </span>
        )}
        <input
          className="input"
          name="name"
          placeholder="Ваше имя"
          value={name}
          onBlur={() => setNameDirty(true)}
          onChange={(e) => handleName(e)}
        />

        {phoneDirty && phoneError && (
          <span style={{ color: "#FF3A3A", fontSize: "12px", margin: "8px 0" }}>
            {phoneError}
          </span>
        )}
        <input
          name="phone"
          className="input"
          value={phone}
          onBlur={() => setPhoneDirty(true)}
          onChange={(e) => handlePhone(e)}
          placeholder="Ваш телефон"
          type="tel"
        />

        {emailDirty && emailError && (
          <span style={{ color: "#FF3A3A", fontSize: "12px", margin: "8px 0" }}>
            {emailError}
          </span>
        )}
        <input
          className="input"
          name="email"
          placeholder="Ваш e-mail"
          value={email}
          onBlur={() => setEmailDirty(true)}
          onChange={(e) => handleEmail(e)}
        />

        <span className="form__policy">
          Я согласен, с{" "}
          <a href={"https://doctorlaw.pro/#contacts"} target="_blank">
            политикой обработки персональных данныx и получением рекламных
            материалов
          </a>
        </span>

        <button
          onClick={handleSubmit}
          className="button button_rectangle"
          disabled={nameError || phoneError || emailError || loading}
          style={{ width: "290px" }}
        >
          {!loading ? 'отправить и скачать' : <span className="loader" />}
          
        </button>
      </div>
    </ChecklistWrapper>
  );
}
